
  import { defineComponent, ref, computed, watch, getCurrentInstance } from "vue"
  import { getKeycloak } from "@/plugins/keycloak";
  import AddressCard from "@/components/shared/AddressCard.vue";
  import CareReceiverEdit from "@/components/shared/CareReceiverEdit.vue";
  import Tasks from "@/components/shared/Tasks.vue"
  import { CareReceiver } from "@/models/care_receivers";
  import { Address } from "@/models/addresses";
  import { apiDataMorpher, extractRelationshipIds } from "@/utils/apiDataHelper"
  import { resourceUrl } from "@/utils/dataExtractors"
  import { ResourceObject, RelationshipsWithData, ResourceIdentifierObject } from '@/models/jsonapi'
  import { provideRefreshData } from "@/utils/refreshData";
  import FileMetaData from "@/components/shared/FileMetaData.vue"
  import CareReceiverKeyData from "@/components/shared/CareReceiverKeyData.vue"
  import { confirm } from "@/utils/interactionModals"

  interface Props { id: string }

  export default defineComponent({
    components: {
      AddressCard,
      CareReceiverKeyData,
      CareReceiverEdit,
      FileMetaData,
      Tasks
    },
    props: { id: { type: String, required: true } },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const keycloakRef = getKeycloak()
      const role = computed(() => keycloakRef.value.tokenParsed?.resource_access?.['pflegehilfe-senioren-platform']['roles'][0])

      const careReceiver = ref<ResourceObject<string, CareReceiver>>()
      watch(careReceiver, (newValue) => {
        const leadId = ((newValue?.relationships?.lead as RelationshipsWithData)?.data as ResourceIdentifierObject)?.id
        if (leadId) {
          const payload = { queryParams: { 'filter[addressable_id]': leadId } }
          root.$store.dispatch('addresses/get', payload)
        }
      })

      const leadAddress = computed<ResourceObject<string, Address>>(() => root.$store.state.addresses.element)
      const title = "Pflegebedürftige"
      const showLoading = ref(false)

      const refreshData = () => {
        const payload = { id: props.id, queryParams: { include: 'file_meta_datas' } }
        root.$store.dispatch('care_receivers/get', payload).then((r) => {
          careReceiver.value = r
        })
        root.$store.dispatch('tasks/load', { 'filter[taskable_id]': props.id, include:'action_type' } )
      }

      const files = computed(() => careReceiver?.value?.attributes?.file_meta_datas ?? [])

      provideRefreshData(refreshData)
      refreshData()

      const titleActions = ref([
        // {
        //   title: 'Edit',
        //   icon: 'pencil-alt',
        //   tooltip: 'Edit',
        //   onClick: () => alert('boubouu')
        // },
        {
          title: 'PDF erstellen',
          icon: ['fad','file-pdf'],
          tooltip: 'Mit den aktuellen Daten ein PDF erstellen. ACHTUNG: Das aktuelle wird überschrieben.',
          onClick: () => updateCareReceiver({ usecase: 'render_pdf' })
        },
        {
          title: 'Löschen',
          icon: ['fad','trash'],
          tooltip: 'Pflegebedürftigen löschen',
          onClick: async () => {
            const confirmed = await confirm(root, 'Der Eintrag wird gelöscht! Sicher?')
            if (confirmed) {
              root.$store.dispatch('care_receivers/delete', props.id).then( () => root.$router.replace('/') )
            }
          }
        },
        {
          categoryTitle: 'Addressen Verwalten',
          title: 'Addressen',
          tooltip: 'Addressen Verwalten',
          children: [
            {
              title: 'Lead > EB',
              tooltip: 'Lead-Adresse in den EB kopieren',
              onClick: () => updateCareReceiver({usecase: 'l2eb'})
            },
            {
              title: 'PB > EB',
              tooltip: 'Adresse des Pflegebedürftigen in den EB kopieren',
              onClick: () => updateCareReceiver({usecase: 'pb2eb'})
            },
            {
              title: 'EB > Lead',
              tooltip: 'Adresse aus EB in die Leadadresse kopieren',
              onClick: () => updateCareReceiver({usecase: 'eb2l'})
            },
            {
              title: 'EB > PB',
              tooltip: 'Adresse aus EB in die Pflegebedürftigen Adresse kopieren',
              onClick: () => updateCareReceiver({usecase: 'eb2pb'})
            },
            {
              title: 'Paket an Leadadresse',
              tooltip: 'Lead-Adresse als Haushaltkontakt festlegen',
              onClick: () => {
                const data = {attributes: {contactAddressId: leadAddress.value.id}}
                updateHousehold(data)
              }
            },
            {
              title: 'Paket an PB Adresse',
              tooltip: 'Adresse des Pflegebedürftigen als Haushaltkontakt festlegen',
              onClick: () => {
                const pbAdId = careReceiver.value?.attributes?.address?.id
                const data = {attributes: {contactAddressId: pbAdId}}
                updateHousehold(data)
              }
            },
            {
              title: 'PB > PB',
              tooltip: 'Adressen aller Pflegebedürftigen dieses Haushaltes mit dieser Pflegebedürftigen Adresse angleichen.',
              onClick: () => updateCareReceiver({usecase: 'pb2pb'})
            },
          ]
        }
      ])

      const updateCareReceiver = (data: any) => {
        showLoading.value = true
        const payload = { id: props.id, body: data }
        root.$store.dispatch('care_receivers/edit', payload)
          .finally(() => {
            refreshData()
            showLoading.value = false
          })
      }

      const updateHousehold = (data: any) => {
        const hhId = (careReceiver.value?.relationships?.household as any).data.id
        showLoading.value = true
        const payload = { id: hhId, body: data }
        root.$store.dispatch('households/edit', payload).then(() => {
          refreshData()
          showLoading.value = false
        }).catch(() => {showLoading.value = false})
      }

      return {
        careReceiver,
        updateCareReceiver,
        leadAddress,
        title,
        titleActions,
        resourceUrl,
        refreshData,
        showLoading,
        role,
        files,
      }
    }
  })
