
  import { defineComponent, computed } from "vue"
  import { dateTime } from "@/utils/helper"
  import { ResourceObject } from '@/models/jsonapi'
  import { CareReceiver } from "@/models/care_receivers";
  import { User } from "@/models/users";
  import { fullName } from "@/utils/dataExtractors";

  interface Props {
    cr: ResourceObject<string, CareReceiver>
  }

  export default defineComponent({
    name: 'CareReceiverKeyData',
    props: {
      cr: {
        type: Object,
        required: true
      },
    },
    setup(props: Props) {
      const present = (val) => val != null && val != undefined

      const crAtts = computed(()=>props.cr.attributes)

      return {
        dateTime,
        present,
        crAtts,
      }
    }
  })
